






























































import Vue from 'vue';
import Component from 'vue-class-component';
import Utils from '../modules/Utils';
import PropUtils from '../modules/PropUtils';
import { Global, User, Projects } from '@/store';
import cloneDeep from 'lodash/cloneDeep';
import API, { Types } from '@/modules/API';
import { Watch } from 'vue-property-decorator';

@Component({})
export default class ProjectPage extends Vue {
  @User.State('role') role;
  @User.State('isLoading') isLoading;
  @User.State('project') project;
  @User.State('properties') userProperties;
  @User.Mutation('selectProject') selectProject;
  @Projects.State('list') projects;
  @Projects.State('projects_count') projects_count;
  @Projects.State('images') images;
  @Global.State('isSidebarOpen') isSidebarOpen;
  @Global.Mutation('setContentStyles') setContentStyles;
  @Global.State('isMobile') isMobile;
  @Global.Mutation('setAllProjectsTimezone') setAllProjectsTimezone;

  Utils = Utils;
  PropUtils = PropUtils;
  initTime = new Date().getTime();
  filtered_alerts_url = '';

  created() {
    this.setContentStyles();
    this.filtered_alerts_url = localStorage.getItem('filtered_alerts_url');
    this.checkProjectsList();
  }

  @Watch('projects_count')
  @Watch('projects')
  async checkProjectsList(){
    if (this.projects_count === this.projects.length) {
      if (this.projects_count === 1) {
        await this.chooseProject(this.projects[0]);
      }else if (this.filtered_alerts_url){
        await this.selectProjectFromUrl();
      }
    }
  }

  getProjectIdFromUrl(){
    return this.filtered_alerts_url.split('/')[2];
  }

  removeAlertsUrlItem(){
    localStorage.removeItem('filtered_alerts_url');
    this.filtered_alerts_url = '';
  }

  async selectProjectFromUrl(){
    const project_data = this.projects.find((project) => project.id === this.getProjectIdFromUrl());
    if (project_data) {
      await this.chooseProject(project_data);
    }
  }

  async chooseProject(projectData) {
    this.setContentStyles();
    if (projectData !== 'allProjects') {
      this.selectProject(projectData);
    } else {
      const unified = this.unifiyAllProjects();
      this.selectProject(unified);
      await this.setTimezone();
    }
    
    this.$nextTick(() => {
      if (this.filtered_alerts_url && projectData.id !== this.getProjectIdFromUrl()) {
        this.removeAlertsUrlItem();
      }

      if (this.filtered_alerts_url) {
        this.$router.push(this.filtered_alerts_url);
      }else {
        this.$router.push('/fullViewReport');
      }
    });
  }

  async setTimezone(){
    let projects_timezone = await this.fetchTimezoneProperty();
    projects_timezone = projects_timezone 
      ? projects_timezone.hasOwnProperty('timezone')
        ? projects_timezone.timezone
        : projects_timezone
      : 'Asia/Tel_Aviv';
      
    this.setAllProjectsTimezone(projects_timezone);
  }

  async fetchTimezoneProperty(){
    const property = await API.get(Types.COMPANIES, `${this.projects[0].company}/${Types.PROJECTS}/${this.projects[0].id}/properties`, {name: 'project.timezone'});
    return property && property.results && property.results[0] && property.results[0].value ? property.results[0].value : '';
  }

  unifiyAllProjects() {
    const idsAllProjects = [];
    const namesAllProjects = [];
    const companiesAllProjects = [];
    
    this.projects.forEach((item) => {
      idsAllProjects.indexOf(item.id) === -1 ? idsAllProjects.push(item.id) : console.log('This item already exists');
      namesAllProjects.indexOf(item.name) === -1 ? namesAllProjects.push(item.name) : console.log('This item already exists');
      companiesAllProjects.indexOf(item.company) === -1 ? companiesAllProjects.push(item.company) : console.log('This item already exists');
    });
    const allProjectsData = {
      id: 'allProjects',
      name: 'All Projects',
      description: `The following projects are shown: ${namesAllProjects.toString()}`,
      created: Date().toString(),
      company: 'allProjects',
      modified: null,
      idsAllProjects: [...idsAllProjects],
      namesAllProjects: [...namesAllProjects],
      companiesAllProjects: [...companiesAllProjects],
      projects: cloneDeep(this.projects)
    };
    return allProjectsData;
  }
}
